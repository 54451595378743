<template>
  <div>
    <div class="flex-between">
      <div style="display: flex">
        <el-button @click="getList" size='small'>刷新数据</el-button>
        <el-input
          placeholder="描述内容"
          v-model="search"
          class="input-with-select"
          @keydown.enter.native="handlePageChange(1)"
          clearable
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="handlePageChange(1)"
          ></el-button>
        </el-input>
      </div>
      <el-button icon="el-icon-plus" @click="handleAdd(null)" type="primary"
        >新增标题</el-button
      >
    </div>
    <el-table
      :loading="loading"
      :data="dataList"
      height="calc(100vh - 388px)"
      style="width: 100%; margin: 20px 0"
    >
      <el-table-column prop="id" label="ID" width="100"> </el-table-column>
      <el-table-column prop="description" label="描述"> </el-table-column>
      <el-table-column label="操作" width="200px">
        <template slot-scope="scope">
          <el-button class='button-small' type="primary" @click="handleAdd(scope.row)">修改</el-button>
          <el-button class='button-small' type="danger" @click="handleDelete(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="margin-top: 20px; text-align: right"
      background
      @current-change="handlePageChange"
      :current-page="page"
      :page-size="15"
      layout="total, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import {
  getDescriptionList,
  editDescription,
  deleteDescription
} from '@/api/classify'

export default {
  name: 'Description',
  props: {
    categoryId: Number
  },
  data () {
    return {
      loading: false,
      search: null,
      page: 1,
      total: 0,
      dataList: []
    }
  },
  beforeRouteEnter (to, from, next) {
    next((vm) => vm.getList())
  },
  watch: {
    categoryId (newVal) {
      if (newVal) {
        this.search = null
        this.handlePageChange(1)
      }
    }
  },
  methods: {
    handlePageChange (page) {
      this.page = page
      this.getList()
    },
    getList () {
      if (!this.categoryId) {
        return false
      }
      this.loading = true
      getDescriptionList({
        page: this.page,
        pageSize: 15,
        desc: this.search,
        categoryId: this.categoryId
      })
        .then((res) => {
          this.dataList = res.list
          this.total = res.total
        })
        .finally(() => (this.loading = false))
    },
    handleAdd (item) {
      this.$prompt('请输入描述', item ? '编辑' : '新增', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputType: 'text',
        inputValue: item ? item.description : ''
      })
        .then(({ value }) => {
          if (value) {
            editDescription({
              id: item ? item.id : null,
              description: value,
              categoryId: this.categoryId
            }).then(() => {
              this.handlePageChange(this.page)
              this.$message.success('保存成功')
            })
          }
        })
        .catch(() => {})
    },
    handleDelete (id) {
      this.$confirm('此操作将永久删除该描述， 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteDescription(id).then(() => {
            this.$message.success('删除成功')
            this.handlePageChange(1)
          })
        })
        .catch(() => {})
    }
  }
}
</script>

<style scoped>
.input-with-select {
  margin-left: 20px;
}
</style>
